@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  /* background-color: #484A5F; */
}

/* 
https://mycolor.space/?hex=%23000F3D&sub=1
 */

body {
  /* background-color: #484a5f; */
  background-color: #F9F8FF;
  /* background-color: #f1f1e6; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1500px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.my-intro {
  background-image: linear-gradient(to right top, #49ddb5, #02c9ba, #00b4b9, #009fb3, #0089a7);  font-size: 5vw;
  color: #f1f1e6; /* not a bad option */
  /* color: #FFFADE; */
  font-family: 'Squada One', cursive;
}

.intro-text h1{
  font-size: 8rem;
  text-align: left;
  padding-top: 5rem;
  padding-left: 4rem;
  margin: 0;
}

.intro-text h2{
  font-size: 3rem;
  text-align: left;
  margin-left: 20rem;
}

.line-3{
  font-size: 2rem;
  text-align: left;
  padding-left: 7rem;
  padding-bottom: 5rem;
  margin: 0;
}

/********** Small devices only **********/
@media (min-width: 10px) and (max-width: 767px) {
  .intro-text h1{
    font-size: 16vw;
    text-align: center;
    padding: 5rem 1.5rem .75rem 1.5rem;
    margin: 0;
    max-width: 767px;
  }
  
  .intro-text h2{
    font-size: 8.5vw;
    text-align: right;
    margin: 0 12% 0 0;
  }
  
  .line-3 {
    font-size: 6vw;
    text-align: left;
    padding: .75rem 0 5rem 0;
    margin: 0 0 0 15%;
  }
}

/* ===== Experience ===== */

.experience {
  background-color: #F5F5F5;
  color: #2a2b35;
  padding-top: 4rem;
  padding-bottom: 3.5rem;
}

.about-me {
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 62rem;
  margin: auto;
  padding: 1rem;
}

p {
  font-size: 1.3rem;
  line-height: 1.5;
  margin: 0;
}

.line {
  width: 17%;
  margin: auto;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  border-color: #083c77;
}

.flex-job-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: .5rem;
}

.flex-item {
  width: 21rem;
  padding: 1rem;
  margin: auto;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.job-title {
  font-size: 1.7rem;
  text-align: center;
}

.work-position {
  float: left;
  text-align: left;
  font-size: 1.2rem;
}

.work-date {
  text-align: right;
  float: right;
  font-size: 1.2rem;
}

.location {
  font-size: 1.2rem;
}

/* ===== School ====== */

.education {
  /* background-color: #f1f1e6; */
  background-color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: #2a2b35;

}

.flex-school-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  font-family: 'Montserrat', sans-serif;
}

.flex-school-item {
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 29rem;
  /* color: #1c1e31; */
  padding: 1rem;
}

img {
  width: 15%;
}

.school-name {
  /* text-align: center; */
}

.date {
  float: right;
}

.degree {
  float: left;
}

/* ===== Contact ===== */

.contact {
  color: #2a2b35;
  text-align: center;
  background-image: linear-gradient(to right top, #49ddb5, #02c9ba, #00b4b9, #009fb3, #0089a7);
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
}

.contact p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5rem;
}

.contact img {
  width: 3rem;
  vertical-align: middle;
  margin: 0 1rem 0 1rem;
}
/* ===== Contact End ===== */

/********** Large Devices **********/
@media (min-width: 768px) {

}

/********** Small devices only **********/
@media (min-width: 10px) and (max-width: 767px) {

}